/* Poppins Font - All weights */
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

/* Noto Sans TC - All Weights */
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@100..900&display=swap');

/* Recoleta Bold Only */
@font-face {
  font-family: 'Recoleta';
  /* Name your font */
  src: url('./fonts/Recoleta-Bold.ttf') format('truetype');
}

:root {
  /* Background color */
  --primary-background-color: #ffffff;
  --secondary-background-color: #262626;

  /* Colors */
  --primary-black-color: #262626;
  --secondary-black-color: #625B5B;
  --tertiary-black-color: #8B8B8B;

  /* Text colors */
  --primary-text-color: #262626;
  --secondary-text-color: #aaaaaa;
  --tertiary-text-color: #ffffff;
  --subtle-text-color: #333333;
  --hover-text-color: #000000;

  /* Font family | Base: 16px */
  --heading-font-family: 'Recoleta', sans-serif;
  --primary-font-family: 'Poppins', sans-serif;
  /* For Chinese: "Noto Sans TC", sans-serif */

  /* Line Height for body-font */
  --body-font-line-height: 170%;

  /* Border Radius */
  --image-border-radius: 12px;

  /* Max-Width */
  --max-width-pc-view: 1000px;
  --max-width-tablet-view: 900px;
  --max-width-mobile-view: 358px;

  /* Margin & Padding*/
  --padding-pc-view: 1em;
  --padding-tablet-view: 1em;
  --padding-mobile-view: 1em;

  /* Custom Colors */
  --shopback-primary-color: #D83D34;
  --shopback-secondary-color: #FF8787;
  --blessing-primary-color: #CD5229;
  --blessing-secondary-color: #FFD2D2;
  --givecircle-primary-color: #315293;
  --givecircle-secondary-color: #F5AA38;
}