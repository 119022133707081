/* Project Wide Variables */
@import '../../styles/styleVariables.stye.css';

.page-not-found-container {
    display: flex;
    flex-direction: column;
    gap: 24px;
    align-items: center;
    margin-top: 120px;
    margin-bottom: 64px;
}

.go-home-container {
    display: flex;
    flex-direction: row;
    gap: 16px;
    color: var(--primary-black-color);
    text-decoration: none;
    transition: all 0.2s;
}

.go-home-container:hover {
    transform: scale(1.05);
}

/* For Mobile */
@media (max-width: 767px) {
    .page-not-found-container {
        margin-top: 64px;
        margin-bottom: 0px;
    }
}