/* Project Wide Variables */
@import '../../styles/styleVariables.stye.css';

.navbar-box {
  position: sticky;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(10px);
  width: 100%;
  z-index: 10;
  padding: 8px 0px;
  margin-top: 40px;
  margin-bottom: 112px;
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--primary-black-color);
  z-index: 10;
}

.navbar-brand {
  text-decoration: none;
  color: var(--primary-black-color) !important;
  /* z-index: 10; */
  position: relative;
  font-family: 'Recoleta',
    sans-serif !important;
}

.nav-links {
  display: flex;
  gap: 32px;
}

.nav-link {
  margin-right: 40px;
  text-decoration: none;
  transition: all 0.2s;
  color: var(--primary-black-color);
}

.nav-link:hover {
  text-decoration: underline;
}

/* Styles for hamburger menu icon */
.hamburger-menu {
  display: none;
  cursor: pointer;
  transition: all 0.01s;
  position: relative;
  z-index: 100;
}

.hamburger-menu img {
  border-radius: 0px;
}

.close-hamburger-menu {
  position: relative;
  z-index: 100;
  transition: all 0.01s;
}


/* Additional styles for showing the menu */
.show-menu .nav-links {
  display: flex;
  flex-direction: column;
}

.show-menu .nav-link {
  margin: 10px 0;
}

.dropdown-text {
  margin-left: 8px;
}

.dropdown-toggle {
  stroke: var(--primary-black-color);
  text-decoration: none;
  transition: all 0.2s;
  display: flex;
  align-items: center;
}

.dropdown-toggle:hover {
  transform: scale(1.05);
}

.dropdown-menu {
  position: absolute;
  z-index: 15;
  width: 100px;
  background-color: var(--primary-background-color);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.dropdown-item {
  padding: 8px 28px;
  font-size: 1.2em;
  text-align: center;
  /* Align the text to the right */
  transition: all 0.2s
}


.dropdown-item:hover {
  background-color: var(--primary-black-color);
  color: var(--primary-background-color);
}


/* Add styles for mobile view */
@media (max-width: 767px) {

  .navbar-box {
    padding: 8px 0px;
    margin-top: 32px;
    margin-bottom: 64px;
  }

  .nav-links {
    display: none;
  }

  .nav-link {
    margin: 0px;
  }

  .nav-link:hover {
    text-decoration: none;
  }

  .hamburger-menu {
    display: block;
  }

  .overlay {
    position: fixed;
    z-index: 5;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--primary-background-color);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .menu-icons {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    margin: 0px;
  }

  .menu-icons a {
    text-decoration: none;
    color: var(--primary-black-color);
    transition: all 0.2s;
  }

  .menu-icons a:hover {
    color: var(--primary-black-color);
  }

  .language-option {
    margin-top: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .language-link {
    cursor: pointer;
    margin: 0 5px;
    color: var(--secondary-black-color);
  }

  .language-link.active {
    color: var(--primary-black-color);
  }

  .separator {
    color: var(--secondary-black-color);
    margin-left: 16px;
    margin-right: 16px;
  }

}