/* Project Wide Variables */
@import '../../styles/styleVariables.stye.css';

.content {
  position: relative;
  z-index: 1;
}

.background-png {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 120%;
  z-index: 0;
  background: url('../../assets/home-bg-gradient.png') no-repeat center center;
}

.home-header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: auto;
  gap: 64px;
}

.text-container {
  display: flex;
  flex: 6;
  color: var(--primary-black-color);
  align-items: center;
}

.image-container {
  display: flex;
  height: auto;
  justify-content: flex-end;
  align-self: stretch;
  max-width: 320px;
  align-items: center;

}

.responsive-image {
  width: 100%;
  /* height: 100%; */
  object-fit: cover;
  height: auto;
}

.projects-area {
  display: flex;
  flex-direction: column;
  margin-top: 120px;
  gap: 64px;
}

.project-items {
  display: flex;
  flex-direction: column;
  gap: 64px;
  z-index: 20;
}

@media (max-width: 768px) {

  .home-header-container {
    flex-direction: column;
    justify-content: center;
    gap: 32px;
  }

  .projects-area {
    margin-top: 64px;
    gap: 32px;
  }

  .project-items {
    display: flex;
    flex-direction: column;
    gap: 40px
  }

  .text-container {
    margin-right: 0;
    /* text-align: justify; */
  }

  .image-container {
    max-width: 100%;
  }

  .responsive-image {
    width: 100%;
    height: auto;
    object-fit: cover;
  }

}