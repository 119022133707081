@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@100..900&display=swap);
/* Poppins Font - All weights */

/* Noto Sans TC - All Weights */

/* Recoleta Bold Only */
@font-face {
  font-family: 'Recoleta';
  /* Name your font */
  src: url(/static/media/Recoleta-Bold.f4d84907.ttf) format('truetype');
}

:root {
  /* Background color */
  --primary-background-color: #ffffff;
  --secondary-background-color: #262626;

  /* Colors */
  --primary-black-color: #262626;
  --secondary-black-color: #625B5B;
  --tertiary-black-color: #8B8B8B;

  /* Text colors */
  --primary-text-color: #262626;
  --secondary-text-color: #aaaaaa;
  --tertiary-text-color: #ffffff;
  --subtle-text-color: #333333;
  --hover-text-color: #000000;

  /* Font family | Base: 16px */
  --heading-font-family: 'Recoleta', sans-serif;
  --primary-font-family: 'Poppins', sans-serif;
  /* For Chinese: "Noto Sans TC", sans-serif */

  /* Line Height for body-font */
  --body-font-line-height: 170%;

  /* Border Radius */
  --image-border-radius: 12px;

  /* Max-Width */
  --max-width-pc-view: 1000px;
  --max-width-tablet-view: 900px;
  --max-width-mobile-view: 358px;

  /* Margin & Padding*/
  --padding-pc-view: 1em;
  --padding-tablet-view: 1em;
  --padding-mobile-view: 1em;

  /* Custom Colors */
  --shopback-primary-color: #D83D34;
  --shopback-secondary-color: #FF8787;
  --blessing-primary-color: #CD5229;
  --blessing-secondary-color: #FFD2D2;
  --givecircle-primary-color: #315293;
  --givecircle-secondary-color: #F5AA38;
}
/* Project Wide Variables */

.project-card {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  vertical-align: middle;
  grid-gap: 64px;
  gap: 64px;
  transition: all 0.2s;
  text-decoration: none !important;
  box-shadow: 0 4px 18px 0 rgba(119, 124, 139, 0.2);
  border-radius: 12px;
  background-color: #FFFFFF;
}

.project-card:hover {
  cursor: pointer;
  transform: scale(1.05);
}


.project-image-area {
  /* flex: 1; */
  display: flex;
  justify-content: center;
  text-decoration: none;
  align-self: stretch;
  transition: all 0.2s;
  height: 336px;
  max-width: 468px;
}

.project-card .project-image-area .project-image-link {
  height: 100%;
  /* Ensure it stretches to match the parent's height */
  object-fit: cover;
}


.project-card .project-image-area .project-image-link .project-short-header-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 12px 0px 0px 12px;
}

.project-text-area {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  grid-gap: 32px;
  gap: 32px;
  transition: all 0.2s;
  max-height: 280px;
  max-width: 468px !important;
  padding-top: 24px;
  padding-right: 24px;
}

.project-text-area .title-area {
  display: flex;
  flex-direction: column;
  grid-gap: 4px;
  gap: 4px;
}

.project-text-area .title-area span {
  color: var(--secondary-black-color);
}

.project-text-area .title-area h1 {
  color: var(--primary-black-color) !important;
}

.project-text-area .desc-area {
  color: var(--primary-black-color);
  max-height: 160px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  text-overflow: ellipsis;
}


/* Add styles for mobile view */
@media (max-width: 767px) {
  .project-card {
    flex-direction: column;
    grid-gap: 16px;
    gap: 16px;
  }

  .project-card:hover {
    /* reset the hover prop */
    transform: none;
  }

  .project-text-area {
    max-height: 100% !important;
    padding: 16px 16px 24px 16px;
    margin: 0px;
    grid-gap: 24px;
    gap: 24px;
  }

  .project-text-area .title-area {
    display: flex;
    flex-direction: column;
    grid-gap: 8px;
    gap: 8px;
  }

  .project-text-area .desc-area {
    color: var(--primary-black-color);
    max-height: 100% !important;
    overflow: visible;
    display: flex;
  }

  .project-card .project-image-area .project-image-link .project-short-header-image {
    border-radius: 12px 12px 0px 0px;
  }

}
/* Project Wide Variables */

.content {
  position: relative;
  z-index: 1;
}

.background-png {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 120%;
  z-index: 0;
  background: url(/static/media/home-bg-gradient.a599c626.png) no-repeat center center;
}

.home-header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: auto;
  grid-gap: 64px;
  gap: 64px;
}

.text-container {
  display: flex;
  flex: 6 1;
  color: var(--primary-black-color);
  align-items: center;
}

.image-container {
  display: flex;
  height: auto;
  justify-content: flex-end;
  align-self: stretch;
  max-width: 320px;
  align-items: center;

}

.responsive-image {
  width: 100%;
  /* height: 100%; */
  object-fit: cover;
  height: auto;
}

.projects-area {
  display: flex;
  flex-direction: column;
  margin-top: 120px;
  grid-gap: 64px;
  gap: 64px;
}

.project-items {
  display: flex;
  flex-direction: column;
  grid-gap: 64px;
  gap: 64px;
  z-index: 20;
}

@media (max-width: 768px) {

  .home-header-container {
    flex-direction: column;
    justify-content: center;
    grid-gap: 32px;
    gap: 32px;
  }

  .projects-area {
    margin-top: 64px;
    grid-gap: 32px;
    gap: 32px;
  }

  .project-items {
    display: flex;
    flex-direction: column;
    grid-gap: 40px;
    gap: 40px
  }

  .text-container {
    margin-right: 0;
    /* text-align: justify; */
  }

  .image-container {
    max-width: 100%;
  }

  .responsive-image {
    width: 100%;
    height: auto;
    object-fit: cover;
  }

}
/* Project Wide Variables */

.project-container {
  display: flex;
  flex-direction: column;
  grid-gap: 0px;
  gap: 0px;
}

.title {
  margin-bottom: 40px;
}

.title h1 {
  color: var(--primary-black-color);
}

.header-content {
  display: flex;
  justify-content: space-between;
  grid-gap: 24px;
  gap: 24px;
  margin-top: 0px !important;
}

.header-description {
  width: 65%;
  color: var(--primary-black-color);
}

.roles-and-date {
  display: flex;
  flex-direction: column;
  width: auto;
  color: var(--primary-black-color);
  grid-gap: 24px;
  gap: 24px;
}

.roles,
.date {
  width: 100%;
  color: var(--primary-black-color);
}

.roles ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.roles li {
  margin-bottom: 0px;
}

.header-image-container {
  display: flex;
  width: 100%;
  max-height: 80vh;
  overflow: hidden;
  margin-top: 64px;
  margin-bottom: 64px;
  border-radius: var(--image-border-radius);
}

.header-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* object-position: center; */
}

.side-heading {
  display: block;
  margin-top: 0px;
  margin-bottom: 4px !important;
}

.article-container {
  display: flex;
  flex-direction: column;
}

.article-container img {
  width: 100%;
  height: auto;
  object-fit: cover;
  object-position: center;
}

.article-section .html-section {
  margin-top: 64px;
  margin-bottom: 64px;
}

.article-section h1,
h2,
h3,
h4 {
  /* Heading-1 */
  font-family: var(--heading-font-family);
  font-weight: 700;
  font-size: 3.125em;
  line-height: 140%;
  margin-top: 0px !important;
  margin-bottom: 40px !important;
}

.article-section p {
  margin-top: 0px;
  margin-bottom: 64px !important;

  /* body-font */
  font-family: var(--primary-font-family);
  font-weight: 400;
  /* Medium */
  font-size: 1.25em;
  line-height: 160%;
  margin: 0px;
}

.article-section:nth-child(odd) {
  background-color: var(--primary-black-color);
  color: var(--tertiary-text-color);
}

.article-section:nth-child(even) {
  background-color: var(--primary-background-color);
  color: var(--primary-black-color);
}

/* .go-to-top-btn {
  position: fixed;
  bottom: 20px;
  right: 20px;
  padding: 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  cursor: pointer;
  z-index: 1000;
} */

.go-to-top-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  grid-gap: 8px;
  gap: 8px;
  position: fixed;
  bottom: 36px;
  right: 36px;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  padding: 0;
  background-color: var(--primary-background-color);
  color: var(--primary-black-color);
  border: none;
  cursor: pointer;
  z-index: 1000;
  box-shadow: 2px 4px 16px 0px rgba(0, 0, 0, 0.2);
  font-weight: 500;
  font-size: 14px;
  transition: all 0.2s ease-in-out;
}

.go-to-top-btn svg {
  transform: rotate(180deg);
  stroke: var(--primary-black-color);
}

.go-to-top-btn:hover {
  color: var(--secondary-black-color);
  transform: scale(1.1);
}

/* For PC */
@media (min-width: 1024px) {
  .html-section {
    margin-top: 0px;
    max-width: var(--max-width-pc-view);
    margin-left: auto;
    margin-right: auto;
    /* padding: var(--padding-pc-view); */
  }
}

/* For Tablet */
@media (max-width: 1023px) and (min-width: 768px) {
  .html-section {
    margin-top: 0px;
    max-width: var(--max-width-tablet-view);
    margin-left: auto;
    margin-right: auto;
    /* padding: var(--padding-tablet-view); */
  }
}

/* For Mobile */
@media (max-width: 767px) {
  .html-section {
    max-width: var(--max-width-mobile-view);
    margin-left: auto;
    margin-right: auto;
    margin-top: 24px !important;
    margin-bottom: 40px !important;
  }

  .project-container {
    flex-direction: column;
  }

  .title h1 {
    font-size: 2em;
    margin-top: 16px;
    margin-bottom: 12px;
  }

  .header-content {
    flex-direction: column;
    grid-gap: 40px;
    gap: 40px;
  }

  .header-description {
    width: 100%;
    /* Adjust width for mobile */
    font-size: 1em;
  }

  .roles-and-date {
    width: 100%;
    grid-gap: 16px;
    gap: 16px;
  }

  .header-image-container {
    margin-top: 36px;
    margin-bottom: 24px;
  }

  .go-to-top-btn {
    bottom: 12px;
    right: 12px;
  }
}
/* Project Wide Variables */

.page-not-found-container {
    display: flex;
    flex-direction: column;
    grid-gap: 24px;
    gap: 24px;
    align-items: center;
    margin-top: 120px;
    margin-bottom: 64px;
}

.go-home-container {
    display: flex;
    flex-direction: row;
    grid-gap: 16px;
    gap: 16px;
    color: var(--primary-black-color);
    text-decoration: none;
    transition: all 0.2s;
}

.go-home-container:hover {
    transform: scale(1.05);
}

/* For Mobile */
@media (max-width: 767px) {
    .page-not-found-container {
        margin-top: 64px;
        margin-bottom: 0px;
    }
}
/* Project Wide Variables */

.password-card {
  background-color: white;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  padding: 40px 60px;
  border-radius: 10px;
  /* width: 400px; */
  max-width: 400px;
  margin: 0 auto;
}

.password-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
}

.wrapper-header-content {
  text-align: center;
  display: flex;
  flex-direction: column;
  object-position: center;
  grid-gap: 0px;
  gap: 0px;
  margin-bottom: 64px;
}

.wrapper-header-content h3 {
  margin-bottom: 32px;
  margin-top: 0px;
}

.wrapper-header-content .upper-lower-text {
  margin-top: 24px;
  color: var(--secondary-black-color);
}

.wrapper-header-content p {
  font-size: 1em;
  margin: 0px;
}

.wrapper-header-content img {
  width: 50%;
  margin-left: auto;
  margin-right: auto;
}

.password-content {
  display: flex;
  flex-direction: column;
  grid-gap: 0px;
  gap: 0px;
}

.input-area {
  display: flex;
  grid-gap: 8px;
  gap: 8px;
  align-items: center;
  justify-content: center;
  border: solid 1px;
  border-radius: 4px;
  padding: 8px 16px;
  color: var(--tertiary-black-color);
}

.error-border {
  border-color: #E23E0D;
}

.input-area input {
  height: 32px !important;
}


.password-input {
  width: 100%;
  border: none;
}

.show-password-container {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  stroke: var(--primary-black-color);
  color: var(--primary-black-color);
  transition: all 0.2s;
}

.show-password-container:hover {
  stroke: var(--primary-black-color);
  color: var(--primary-black-color);
}

.error-text {
  margin-top: 4px;
  color: #E23E0D;
}

.password-button {
  margin-top: 16px;
  padding: 8px;
  border: none;
  background-color: var(--primary-black-color);
  color: var(--primary-background-color);
  font-size: 1em;
  font-weight: var(--primary-font-medium-weight);
  cursor: pointer;
  width: 100%;
  height: 56px;
  transition: all 0.2s;
  /* Smooth transition on color change */
  border-radius: 4px;
}

.password-button:hover {
  background-color: var(--secondary-black-color);
}

.next-project-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 64px;
}

.next-project-container .next-label {
  margin-bottom: 16px;
  color: var(--primary-text-color);
  font-weight: var(--primary-font-semibold-weight);
  font-size: 1.6em;
}

.next-project-container .next-project {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  grid-gap: 8px;
  gap: 8px;
  text-decoration: none;
  transition: all 0.2s;
}

.next-project-container .next-project:hover {
  /* margin-left: -8px;
    margin-right: -8px; */
  cursor: pointer;
  transform: scale(1.05);
}

.next-project-container .next-project .project-data {
  /* color: var(--primary-text-color); */
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  grid-gap: 4px;
  gap: 4px;
}

.next-project-container .next-project .project-data span {
  color: var(--secondary-black-color);
  font-size: 1.2em;
}

.next-project-container .next-project .project-data h1 {
  color: var(--primary-black-color);
  text-decoration: none;
}

.next-project-container .next-project .project-button {
  display: flex;
  align-items: center;
  grid-gap: 16px;
  gap: 16px;
  color: var(--primary-black-color);
  text-decoration: none;
}

.next-project-container .next-project .project-button button:hover {
  background-color: var(--primary-text-color);
}


/* For Mobile */
@media (max-width: 767px) {
  .password-card {
    padding: 20px 20px;
    margin-top: -32px;
  }

  .wrapper-header-content {
    margin-bottom: 40px;
  }

  .next-project-container .next-project {
    display: flex;
    flex-direction: column;
    grid-gap: 32px;
    gap: 32px;
  }
}
/* Project Wide Variables */

.navbar-box {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.2);
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  width: 100%;
  z-index: 10;
  padding: 8px 0px;
  margin-top: 40px;
  margin-bottom: 112px;
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--primary-black-color);
  z-index: 10;
}

.navbar-brand {
  text-decoration: none;
  color: var(--primary-black-color) !important;
  /* z-index: 10; */
  position: relative;
  font-family: 'Recoleta',
    sans-serif !important;
}

.nav-links {
  display: flex;
  grid-gap: 32px;
  gap: 32px;
}

.nav-link {
  margin-right: 40px;
  text-decoration: none;
  transition: all 0.2s;
  color: var(--primary-black-color);
}

.nav-link:hover {
  text-decoration: underline;
}

/* Styles for hamburger menu icon */
.hamburger-menu {
  display: none;
  cursor: pointer;
  transition: all 0.01s;
  position: relative;
  z-index: 100;
}

.hamburger-menu img {
  border-radius: 0px;
}

.close-hamburger-menu {
  position: relative;
  z-index: 100;
  transition: all 0.01s;
}


/* Additional styles for showing the menu */
.show-menu .nav-links {
  display: flex;
  flex-direction: column;
}

.show-menu .nav-link {
  margin: 10px 0;
}

.dropdown-text {
  margin-left: 8px;
}

.dropdown-toggle {
  stroke: var(--primary-black-color);
  text-decoration: none;
  transition: all 0.2s;
  display: flex;
  align-items: center;
}

.dropdown-toggle:hover {
  transform: scale(1.05);
}

.dropdown-menu {
  position: absolute;
  z-index: 15;
  width: 100px;
  background-color: var(--primary-background-color);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.dropdown-item {
  padding: 8px 28px;
  font-size: 1.2em;
  text-align: center;
  /* Align the text to the right */
  transition: all 0.2s
}


.dropdown-item:hover {
  background-color: var(--primary-black-color);
  color: var(--primary-background-color);
}


/* Add styles for mobile view */
@media (max-width: 767px) {

  .navbar-box {
    padding: 8px 0px;
    margin-top: 32px;
    margin-bottom: 64px;
  }

  .nav-links {
    display: none;
  }

  .nav-link {
    margin: 0px;
  }

  .nav-link:hover {
    text-decoration: none;
  }

  .hamburger-menu {
    display: block;
  }

  .overlay {
    position: fixed;
    z-index: 5;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--primary-background-color);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .menu-icons {
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-gap: 16px;
    gap: 16px;
    margin: 0px;
  }

  .menu-icons a {
    text-decoration: none;
    color: var(--primary-black-color);
    transition: all 0.2s;
  }

  .menu-icons a:hover {
    color: var(--primary-black-color);
  }

  .language-option {
    margin-top: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .language-link {
    cursor: pointer;
    margin: 0 5px;
    color: var(--secondary-black-color);
  }

  .language-link.active {
    color: var(--primary-black-color);
  }

  .separator {
    color: var(--secondary-black-color);
    margin-left: 16px;
    margin-right: 16px;
  }

}
/* Project Wide Variables */

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--secondary-black-color);
  margin-top: 112px !important;
  margin-bottom: 60px !important;
}

.left-section {
  width: auto;
  color: var(--secondary-black-color) !important;
}

.socials {
  width: auto;
  display: flex;
  justify-content: center;
  /* Align items to center horizontally */
  align-items: center !important;
  grid-gap: 16px;
  gap: 16px;
}

.social-link {
  height: 40px;
}

.social-link svg rect {
  fill: var(--secondary-black-color);
  transition: all 0.2s;
}

.social-link svg path {
  fill: var(--primary-background-color);
}

.social-link:hover svg rect {
  fill: var(--primary-black-color);
}

.right-section {
  width: auto;
  text-align: right;
}

.email-address {
  text-decoration: none !important;
  color: var(--secondary-black-color);
  transition: all 0.2s;
  margin: 0px;
}

.email-address:hover {
  text-decoration: underline !important;
}


/* Add styles for mobile view */
@media (max-width: 767px) {
  .footer {
    flex-direction: column;
    text-align: center !important;
    grid-gap: 24px;
    gap: 24px;
    margin-top: 64px !important;
  }

  .footer>*:first-child {
    width: 100%;
  }

  .right-section {
    width: 100%;
    text-align: center;
  }
}
/* Project Wide Variables */

body {
    font-family: var(--primary-font-family);
    margin: 0%;
    background-color: var(--primary-background-color);
}

img {
    border-radius: var(--image-border-radius);
}

.brand-font {
    font-family: var(--heading-font-family);
    font-weight: 600;
    font-size: 36px;
    line-height: 140%;
    margin: 0px;
}


.heading-1 {
    font-family: var(--heading-font-family);
    font-weight: 600;
    font-size: 50px;
    line-height: 140%;
    margin: 0px;
}

.heading-2 {
    font-family: var(--primary-font-family);
    font-weight: 600;
    font-size: 36px;
    line-height: 140%;
    margin: 0px;
}

.subtitle-1 {
    font-family: var(--primary-font-family);
    font-weight: 500;
    font-size: 32px;
    line-height: 140%;
    margin: 0px;
}

.subtitle-2 {
    font-family: var(--primary-font-family);
    font-weight: 600;
    font-size: 24px;
    line-height: 140%;
    margin: 0px;
}

.body-font {
    font-family: var(--primary-font-family);
    font-weight: 400;
    font-size: 20px;
    line-height: var(--body-font-line-height);
    margin: 0px;
}

.body-font-2 {
    font-family: var(--primary-font-family);
    font-weight: 600;
    font-size: 20px;
    line-height: var(--body-font-line-height);
    margin: 0px;
}

.small-font {
    font-family: var(--primary-font-family);
    font-weight: 400;
    font-size: 16px;
    line-height: 200%;
    margin: 0px;
}

.second-black-color {
    color: var(--secondary-black-color)
}

ol,
ul {
    padding-left: 24px;
}

/* For PC */
@media (min-width: 1024px) {
    .content-margin {
        max-width: var(--max-width-pc-view);
        margin-left: auto;
        margin-right: auto;
    }
}


/* For Tablet */
@media (max-width: 1023px) and (min-width: 481px) {
    .content-margin {
        max-width: var(--max-width-tablet-view);
        margin-left: auto;
        margin-right: auto;
    }
}

/* For Mobile */
@media (max-width: 767px) {
    .content-margin {
        max-width: var(--max-width-mobile-view);
        margin-left: auto;
        margin-right: auto;
    }

    .brand-font {
        font-size: 32px !important;
    }

    .heading-1 {
        font-size: 32px !important;
    }

    .heading-2 {
        font-size: 24px !important;
    }

    .subtitle-1 {
        font-size: 24px !important;
    }

    .subtitle-2 {
        font-size: 20px !important;
    }

    .body-font {
        font-size: 18px;
    }

    .body-font-2 {
        font-size: 18px;
    }

    .small-font {
        font-size: 14px;
    }
}


/* Projects Related Styling */

.project-container-level-0 {
    display: flex;
    flex-direction: column;
    grid-gap: 64px;
    gap: 64px;
}

.project-container-level-1 {
    display: flex;
    flex-direction: column;
    grid-gap: 48px;
    gap: 48px;
}

.project-container-level-2 {
    display: flex;
    flex-direction: column;
    grid-gap: 20px;
    gap: 20px;
}

.project-container-level-3 {
    display: flex;
    flex-direction: column;
    grid-gap: 8px;
    gap: 8px;
}

.project-h1-spacing {
    margin: 0px 0px 48px 0px !important;
}

.project-subtitle-2-spacing {
    margin: 0px 0px 8px 0px;
}

.project-img {
    width: 100%;
    height: auto;
    margin-top: 32px;
    margin-bottom: 0px;
}

.project-video {
    width: 100%;
    height: auto;
    margin-top: 32px;
    margin-bottom: 0px;
    pointer-events: none;
    touch-action: none;
}

.project-lists {
    margin: 0px;
}




/* Custom Colors */
.shopback-primary-color {
    color: var(--shopback-primary-color);
}

.shopback-secondary-color {
    color: var(--shopback-secondary-color);
}

.blessing-primary-color {
    color: var(--blessing-primary-color);
}

.blessing-secondary-color {
    color: var(--blessing-secondary-color);
}

.blessing-video-img-container {
    display: flex;
    flex-direction: row;
}

.blessing-video-img-container img {
    width: 100%;
    height: auto;
    max-height: 660px;
}

.blessing-video-img-container video {
    max-height: 632px;
}

/* For Mobile */
@media (max-width: 767px) {
    .blessing-video-img-container {
        flex-direction: column;
        grid-gap: 16px;
        gap: 16px;
    }

    .blessing-video-img-container img {
        max-height: unset;
    }
}

.givecircle-primary-color {
    color: var(--givecircle-primary-color);
}

.givecircle-secondary-color {
    color: var(--givecircle-secondary-color);
}

.givecircle-text-href {
    text-decoration: none;
    color: unset;
}

.givecircle-text-href:hover {
    font-weight: 700;
}


.ai-legal-assistant-image-href {
    transition: all 0.2s ease-in-out;
}

.ai-legal-assistant-image-href img {
    margin: 0px;
}

.ai-legal-assistant-image-href:hover {
    cursor: pointer;
    transform: scale(1.05);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.custom-ms-button {
    display: flex;
    align-items: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 10px 50px;
    border: 2px solid #15679B;
    background-color: transparent;
    text-decoration: none;
    font-family: 'Poppins';
    border-radius: 60px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}

.custom-ms-button:hover {
    background-color: #15689b44;
}

.custom-ms-button .ms-icon {
    max-height: 50px;
    width: auto;
    border-radius: 0px !important;
}

.custom-ms-button .ms-text-container {
    display: flex;
    flex-direction: column;
    text-align: left;
    margin-left: 16px;
    color: #15679B;
}

.ms-text-container .unset-line-height {
    line-height: 24px;
}

/* For Mobile */
@media (max-width: 767px) {
    .custom-ms-button {
        padding: 10px 36px;
    }
}
