/* Project Wide Variables */
@import '../../styles/styleVariables.stye.css';

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--secondary-black-color);
  margin-top: 112px !important;
  margin-bottom: 60px !important;
}

.left-section {
  width: auto;
  color: var(--secondary-black-color) !important;
}

.socials {
  width: auto;
  display: flex;
  justify-content: center;
  /* Align items to center horizontally */
  align-items: center !important;
  gap: 16px;
}

.social-link {
  height: 40px;
}

.social-link svg rect {
  fill: var(--secondary-black-color);
  transition: all 0.2s;
}

.social-link svg path {
  fill: var(--primary-background-color);
}

.social-link:hover svg rect {
  fill: var(--primary-black-color);
}

.right-section {
  width: auto;
  text-align: right;
}

.email-address {
  text-decoration: none !important;
  color: var(--secondary-black-color);
  transition: all 0.2s;
  margin: 0px;
}

.email-address:hover {
  text-decoration: underline !important;
}


/* Add styles for mobile view */
@media (max-width: 767px) {
  .footer {
    flex-direction: column;
    text-align: center !important;
    gap: 24px;
    margin-top: 64px !important;
  }

  .footer>*:first-child {
    width: 100%;
  }

  .right-section {
    width: 100%;
    text-align: center;
  }
}