/* Project Wide Variables */
@import '../../styles/styleVariables.stye.css';

.password-card {
  background-color: white;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  padding: 40px 60px;
  border-radius: 10px;
  /* width: 400px; */
  max-width: 400px;
  margin: 0 auto;
}

.password-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
}

.wrapper-header-content {
  text-align: center;
  display: flex;
  flex-direction: column;
  object-position: center;
  gap: 0px;
  margin-bottom: 64px;
}

.wrapper-header-content h3 {
  margin-bottom: 32px;
  margin-top: 0px;
}

.wrapper-header-content .upper-lower-text {
  margin-top: 24px;
  color: var(--secondary-black-color);
}

.wrapper-header-content p {
  font-size: 1em;
  margin: 0px;
}

.wrapper-header-content img {
  width: 50%;
  margin-left: auto;
  margin-right: auto;
}

.password-content {
  display: flex;
  flex-direction: column;
  gap: 0px;
}

.input-area {
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
  border: solid 1px;
  border-radius: 4px;
  padding: 8px 16px;
  color: var(--tertiary-black-color);
}

.error-border {
  border-color: #E23E0D;
}

.input-area input {
  height: 32px !important;
}


.password-input {
  width: 100%;
  border: none;
}

.show-password-container {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  stroke: var(--primary-black-color);
  color: var(--primary-black-color);
  transition: all 0.2s;
}

.show-password-container:hover {
  stroke: var(--primary-black-color);
  color: var(--primary-black-color);
}

.error-text {
  margin-top: 4px;
  color: #E23E0D;
}

.password-button {
  margin-top: 16px;
  padding: 8px;
  border: none;
  background-color: var(--primary-black-color);
  color: var(--primary-background-color);
  font-size: 1em;
  font-weight: var(--primary-font-medium-weight);
  cursor: pointer;
  width: 100%;
  height: 56px;
  transition: all 0.2s;
  /* Smooth transition on color change */
  border-radius: 4px;
}

.password-button:hover {
  background-color: var(--secondary-black-color);
}

.next-project-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 64px;
}

.next-project-container .next-label {
  margin-bottom: 16px;
  color: var(--primary-text-color);
  font-weight: var(--primary-font-semibold-weight);
  font-size: 1.6em;
}

.next-project-container .next-project {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  gap: 8px;
  text-decoration: none;
  transition: all 0.2s;
}

.next-project-container .next-project:hover {
  /* margin-left: -8px;
    margin-right: -8px; */
  cursor: pointer;
  transform: scale(1.05);
}

.next-project-container .next-project .project-data {
  /* color: var(--primary-text-color); */
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  gap: 4px;
}

.next-project-container .next-project .project-data span {
  color: var(--secondary-black-color);
  font-size: 1.2em;
}

.next-project-container .next-project .project-data h1 {
  color: var(--primary-black-color);
  text-decoration: none;
}

.next-project-container .next-project .project-button {
  display: flex;
  align-items: center;
  gap: 16px;
  color: var(--primary-black-color);
  text-decoration: none;
}

.next-project-container .next-project .project-button button:hover {
  background-color: var(--primary-text-color);
}


/* For Mobile */
@media (max-width: 767px) {
  .password-card {
    padding: 20px 20px;
    margin-top: -32px;
  }

  .wrapper-header-content {
    margin-bottom: 40px;
  }

  .next-project-container .next-project {
    display: flex;
    flex-direction: column;
    gap: 32px;
  }
}