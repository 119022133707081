/* Project Wide Variables */
@import '../../styles/styleVariables.stye.css';

.project-card {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  vertical-align: middle;
  gap: 64px;
  transition: all 0.2s;
  text-decoration: none !important;
  box-shadow: 0 4px 18px 0 rgba(119, 124, 139, 0.2);
  border-radius: 12px;
  background-color: #FFFFFF;
}

.project-card:hover {
  cursor: pointer;
  transform: scale(1.05);
}


.project-image-area {
  /* flex: 1; */
  display: flex;
  justify-content: center;
  text-decoration: none;
  align-self: stretch;
  transition: all 0.2s;
  height: 336px;
  max-width: 468px;
}

.project-card .project-image-area .project-image-link {
  height: 100%;
  /* Ensure it stretches to match the parent's height */
  object-fit: cover;
}


.project-card .project-image-area .project-image-link .project-short-header-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 12px 0px 0px 12px;
}

.project-text-area {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 32px;
  transition: all 0.2s;
  max-height: 280px;
  max-width: 468px !important;
  padding-top: 24px;
  padding-right: 24px;
}

.project-text-area .title-area {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.project-text-area .title-area span {
  color: var(--secondary-black-color);
}

.project-text-area .title-area h1 {
  color: var(--primary-black-color) !important;
}

.project-text-area .desc-area {
  color: var(--primary-black-color);
  max-height: 160px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  text-overflow: ellipsis;
}


/* Add styles for mobile view */
@media (max-width: 767px) {
  .project-card {
    flex-direction: column;
    gap: 16px;
  }

  .project-card:hover {
    /* reset the hover prop */
    transform: none;
  }

  .project-text-area {
    max-height: 100% !important;
    padding: 16px 16px 24px 16px;
    margin: 0px;
    gap: 24px;
  }

  .project-text-area .title-area {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .project-text-area .desc-area {
    color: var(--primary-black-color);
    max-height: 100% !important;
    overflow: visible;
    display: flex;
  }

  .project-card .project-image-area .project-image-link .project-short-header-image {
    border-radius: 12px 12px 0px 0px;
  }

}