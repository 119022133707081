/* Project Wide Variables */
@import './styleVariables.stye.css';

body {
    font-family: var(--primary-font-family);
    margin: 0%;
    background-color: var(--primary-background-color);
}

img {
    border-radius: var(--image-border-radius);
}

.brand-font {
    font-family: var(--heading-font-family);
    font-weight: 600;
    font-size: 36px;
    line-height: 140%;
    margin: 0px;
}


.heading-1 {
    font-family: var(--heading-font-family);
    font-weight: 600;
    font-size: 50px;
    line-height: 140%;
    margin: 0px;
}

.heading-2 {
    font-family: var(--primary-font-family);
    font-weight: 600;
    font-size: 36px;
    line-height: 140%;
    margin: 0px;
}

.subtitle-1 {
    font-family: var(--primary-font-family);
    font-weight: 500;
    font-size: 32px;
    line-height: 140%;
    margin: 0px;
}

.subtitle-2 {
    font-family: var(--primary-font-family);
    font-weight: 600;
    font-size: 24px;
    line-height: 140%;
    margin: 0px;
}

.body-font {
    font-family: var(--primary-font-family);
    font-weight: 400;
    font-size: 20px;
    line-height: var(--body-font-line-height);
    margin: 0px;
}

.body-font-2 {
    font-family: var(--primary-font-family);
    font-weight: 600;
    font-size: 20px;
    line-height: var(--body-font-line-height);
    margin: 0px;
}

.small-font {
    font-family: var(--primary-font-family);
    font-weight: 400;
    font-size: 16px;
    line-height: 200%;
    margin: 0px;
}

.second-black-color {
    color: var(--secondary-black-color)
}

ol,
ul {
    padding-left: 24px;
}

/* For PC */
@media (min-width: 1024px) {
    .content-margin {
        max-width: var(--max-width-pc-view);
        margin-left: auto;
        margin-right: auto;
    }
}


/* For Tablet */
@media (max-width: 1023px) and (min-width: 481px) {
    .content-margin {
        max-width: var(--max-width-tablet-view);
        margin-left: auto;
        margin-right: auto;
    }
}

/* For Mobile */
@media (max-width: 767px) {
    .content-margin {
        max-width: var(--max-width-mobile-view);
        margin-left: auto;
        margin-right: auto;
    }

    .brand-font {
        font-size: 32px !important;
    }

    .heading-1 {
        font-size: 32px !important;
    }

    .heading-2 {
        font-size: 24px !important;
    }

    .subtitle-1 {
        font-size: 24px !important;
    }

    .subtitle-2 {
        font-size: 20px !important;
    }

    .body-font {
        font-size: 18px;
    }

    .body-font-2 {
        font-size: 18px;
    }

    .small-font {
        font-size: 14px;
    }
}


/* Projects Related Styling */

.project-container-level-0 {
    display: flex;
    flex-direction: column;
    gap: 64px;
}

.project-container-level-1 {
    display: flex;
    flex-direction: column;
    gap: 48px;
}

.project-container-level-2 {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.project-container-level-3 {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.project-h1-spacing {
    margin: 0px 0px 48px 0px !important;
}

.project-subtitle-2-spacing {
    margin: 0px 0px 8px 0px;
}

.project-img {
    width: 100%;
    height: auto;
    margin-top: 32px;
    margin-bottom: 0px;
}

.project-video {
    width: 100%;
    height: auto;
    margin-top: 32px;
    margin-bottom: 0px;
    pointer-events: none;
    touch-action: none;
}

.project-lists {
    margin: 0px;
}




/* Custom Colors */
.shopback-primary-color {
    color: var(--shopback-primary-color);
}

.shopback-secondary-color {
    color: var(--shopback-secondary-color);
}

.blessing-primary-color {
    color: var(--blessing-primary-color);
}

.blessing-secondary-color {
    color: var(--blessing-secondary-color);
}

.blessing-video-img-container {
    display: flex;
    flex-direction: row;
}

.blessing-video-img-container img {
    width: 100%;
    height: auto;
    max-height: 660px;
}

.blessing-video-img-container video {
    max-height: 632px;
}

/* For Mobile */
@media (max-width: 767px) {
    .blessing-video-img-container {
        flex-direction: column;
        gap: 16px;
    }

    .blessing-video-img-container img {
        max-height: unset;
    }
}

.givecircle-primary-color {
    color: var(--givecircle-primary-color);
}

.givecircle-secondary-color {
    color: var(--givecircle-secondary-color);
}

.givecircle-text-href {
    text-decoration: none;
    color: unset;
}

.givecircle-text-href:hover {
    font-weight: 700;
}


.ai-legal-assistant-image-href {
    transition: all 0.2s ease-in-out;
}

.ai-legal-assistant-image-href img {
    margin: 0px;
}

.ai-legal-assistant-image-href:hover {
    cursor: pointer;
    transform: scale(1.05);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.custom-ms-button {
    display: flex;
    align-items: center;
    width: fit-content;
    padding: 10px 50px;
    border: 2px solid #15679B;
    background-color: transparent;
    text-decoration: none;
    font-family: 'Poppins';
    border-radius: 60px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}

.custom-ms-button:hover {
    background-color: #15689b44;
}

.custom-ms-button .ms-icon {
    max-height: 50px;
    width: auto;
    border-radius: 0px !important;
}

.custom-ms-button .ms-text-container {
    display: flex;
    flex-direction: column;
    text-align: left;
    margin-left: 16px;
    color: #15679B;
}

.ms-text-container .unset-line-height {
    line-height: 24px;
}

/* For Mobile */
@media (max-width: 767px) {
    .custom-ms-button {
        padding: 10px 36px;
    }
}