/* Project Wide Variables */
@import "../../styles/styleVariables.stye.css";

.project-container {
  display: flex;
  flex-direction: column;
  gap: 0px;
}

.title {
  margin-bottom: 40px;
}

.title h1 {
  color: var(--primary-black-color);
}

.header-content {
  display: flex;
  justify-content: space-between;
  gap: 24px;
  margin-top: 0px !important;
}

.header-description {
  width: 65%;
  color: var(--primary-black-color);
}

.roles-and-date {
  display: flex;
  flex-direction: column;
  width: auto;
  color: var(--primary-black-color);
  gap: 24px;
}

.roles,
.date {
  width: 100%;
  color: var(--primary-black-color);
}

.roles ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.roles li {
  margin-bottom: 0px;
}

.header-image-container {
  display: flex;
  width: 100%;
  max-height: 80vh;
  overflow: hidden;
  margin-top: 64px;
  margin-bottom: 64px;
  border-radius: var(--image-border-radius);
}

.header-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* object-position: center; */
}

.side-heading {
  display: block;
  margin-top: 0px;
  margin-bottom: 4px !important;
}

.article-container {
  display: flex;
  flex-direction: column;
}

.article-container img {
  width: 100%;
  height: auto;
  object-fit: cover;
  object-position: center;
}

.article-section .html-section {
  margin-top: 64px;
  margin-bottom: 64px;
}

.article-section h1,
h2,
h3,
h4 {
  /* Heading-1 */
  font-family: var(--heading-font-family);
  font-weight: 700;
  font-size: 3.125em;
  line-height: 140%;
  margin-top: 0px !important;
  margin-bottom: 40px !important;
}

.article-section p {
  margin-top: 0px;
  margin-bottom: 64px !important;

  /* body-font */
  font-family: var(--primary-font-family);
  font-weight: 400;
  /* Medium */
  font-size: 1.25em;
  line-height: 160%;
  margin: 0px;
}

.article-section:nth-child(odd) {
  background-color: var(--primary-black-color);
  color: var(--tertiary-text-color);
}

.article-section:nth-child(even) {
  background-color: var(--primary-background-color);
  color: var(--primary-black-color);
}

/* .go-to-top-btn {
  position: fixed;
  bottom: 20px;
  right: 20px;
  padding: 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  cursor: pointer;
  z-index: 1000;
} */

.go-to-top-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 8px;
  position: fixed;
  bottom: 36px;
  right: 36px;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  padding: 0;
  background-color: var(--primary-background-color);
  color: var(--primary-black-color);
  border: none;
  cursor: pointer;
  z-index: 1000;
  box-shadow: 2px 4px 16px 0px rgba(0, 0, 0, 0.2);
  font-weight: 500;
  font-size: 14px;
  transition: all 0.2s ease-in-out;
}

.go-to-top-btn svg {
  transform: rotate(180deg);
  stroke: var(--primary-black-color);
}

.go-to-top-btn:hover {
  color: var(--secondary-black-color);
  transform: scale(1.1);
}

/* For PC */
@media (min-width: 1024px) {
  .html-section {
    margin-top: 0px;
    max-width: var(--max-width-pc-view);
    margin-left: auto;
    margin-right: auto;
    /* padding: var(--padding-pc-view); */
  }
}

/* For Tablet */
@media (max-width: 1023px) and (min-width: 768px) {
  .html-section {
    margin-top: 0px;
    max-width: var(--max-width-tablet-view);
    margin-left: auto;
    margin-right: auto;
    /* padding: var(--padding-tablet-view); */
  }
}

/* For Mobile */
@media (max-width: 767px) {
  .html-section {
    max-width: var(--max-width-mobile-view);
    margin-left: auto;
    margin-right: auto;
    margin-top: 24px !important;
    margin-bottom: 40px !important;
  }

  .project-container {
    flex-direction: column;
  }

  .title h1 {
    font-size: 2em;
    margin-top: 16px;
    margin-bottom: 12px;
  }

  .header-content {
    flex-direction: column;
    gap: 40px;
  }

  .header-description {
    width: 100%;
    /* Adjust width for mobile */
    font-size: 1em;
  }

  .roles-and-date {
    width: 100%;
    gap: 16px;
  }

  .header-image-container {
    margin-top: 36px;
    margin-bottom: 24px;
  }

  .go-to-top-btn {
    bottom: 12px;
    right: 12px;
  }
}